import './_ProgrammeStyle.scss'

const Programmes = () =>{

	return (
		<div className="slide-container container">
			<div className="wrapper">
				<div className="clash-card barbarian">
					<div className="clash-card__image clash-card__image--barbarian">
						<img src="./prog/intercession.png" alt="intercession"/>
					</div>
					<div className="clash-card__level clash-card__level--barbarian">Programme Hebdomadaire</div>
					<div className="clash-card__unit-name">Mercredi</div>
					<div className="clash-card__unit-description">
						Culte d'intercession à 18h par téléphone et à 21h sur zoom
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--barbarian clearfix">
						<div className="one-third">
							<div className="stat">18<sup>h</sup></div>
							<div className="stat-value">Telephone</div>
						</div>

						<div className="one-third">
							<div className="stat"> Et à </div>
							<div className="stat-value" />
						</div>

						<div className="one-third no-border">
							<div className="stat">21<sup>h</sup></div>
							<div className="stat-value">zoom</div>
						</div>


					</div>

				</div>
				{/*// <!-- end clash-card barbarian-->*/}
			</div>
			{/*// <!-- end wrapper -->*/}

			<div className="wrapper">
				<div className="clash-card archer">
					<div className="clash-card__image clash-card__image--archer">
						<img src="./prog/2.1.png" alt="archer"/>
					</div>
					<div className="clash-card__level clash-card__level--archer">Programme Hebdomadaire</div>
					<div className="clash-card__unit-name">Vendredi</div>
					<div className="clash-card__unit-description">
						Etude Biblique à 21h sur zoom
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--archer clearfix">
						<div className="one-third">
							<div className="stat"><sup></sup></div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third">
							<div className="stat">21<sup>h</sup></div>
							<div className="stat-value">zoom</div>
						</div>

						<div className="one-third no-border">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

					</div>

				</div>
				{/*// <!-- end clash-card archer-->*/}
			</div>
			{/*// <!-- end wrapper -->*/}

			<div className="wrapper">
				<div className="clash-card giant">
					<div className="clash-card__image clash-card__image--giant">
						<img src="./prog/3.png" alt="giant"/>
					</div>
					<div className="clash-card__level clash-card__level--giant">Mensuel</div>
					<div className="clash-card__unit-name">DFF</div>
					<div className="clash-card__unit-description">
						Culte des mamans chaque deuxième jeudi du mois à 21h sur zoom et
						chaque 4e vendredi du mois veillée du DFF de 22h à 2h du matin.
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--giant clearfix">
						<div className="one-third">
							<div className="stat">21<sup>h</sup></div>
							<div className="stat-value">zoom</div>
						</div>

						<div className="one-third">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third no-border">
							<div className="stat">22-2<sup>h</sup></div>
							<div className="stat-value"></div>
						</div>

					</div>

				</div>
				{/*// <!-- end clash-card giant-->*/}
			</div>
			{/*// <!-- end wrapper -->*/}

			<div className="wrapper">
				<div className="clash-card goblin">
					<div className="clash-card__image clash-card__image--goblin">
						<img src="./prog/2.1.png" alt="goblin"/>
					</div>
					<div className="clash-card__level clash-card__level--goblin">Mensuel</div>
					<div className="clash-card__unit-name">Diaconie</div>
					<div className="clash-card__unit-description">
						Chaque 1er et chaque dernier samedi du mois à 21h sur zoom
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--goblin clearfix">
						<div className="one-third">
							<div className="stat">21<sup>h</sup></div>
							<div className="stat-value">zoom</div>
						</div>

						<div className="one-third">
							<div className="stat">1er et dernier</div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third no-border">
							<div className="stat">Samedi</div>
							<div className="stat-value"></div>
						</div>

					</div>

				</div>
				{/*// <!-- end clash-card goblin-->*/}
			</div>
			{/*// <!-- end wrapper -->*/}

			<div className="wrapper">
				<div className="clash-card wizard">
					<div className="clash-card__image clash-card__image--wizard">
						<img src="./prog/5.png" alt="wizard"/>
					</div>
					<div className="clash-card__level clash-card__level--wizard"></div>
					<div className="clash-card__unit-name">Jeunesse</div>
					<div className="clash-card__unit-description">
						Rencontre tous les mardis à 20h sur Google meet;
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--wizard clearfix">
						<div className="one-third">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third">
							<div className="stat">20<sup>h</sup></div>
							<div className="stat-value">Google Meet</div>
						</div>

						<div className="one-third no-border">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

					</div>

				</div>
				{/*// <!-- end clash-card wizard-->*/}
			</div>
			{/*// <!-- end wrapper -->*/}
			<div className="wrapper">
				<div className="clash-card giant">
					<div className="clash-card__image clash-card__image--wizard">
						<img src="./prog/6.png" alt="wizard"/>
					</div>
					<div className="clash-card__level clash-card__level--wizard"></div>
					<div className="clash-card__unit-name">Dimanche</div>
					<div className="clash-card__unit-description">
						culte dominicale de 14h à 16h.
					</div>

					<div className="clash-card__unit-stats clash-card__unit-stats--giant clearfix">
						<div className="one-third">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third">
							<div className="stat">14-16<sup>h</sup></div>
							<div className="stat-value"></div>
						</div>

						<div className="one-third no-border">
							<div className="stat"></div>
							<div className="stat-value"></div>
						</div>

					</div>

				</div>
				{/*// <!-- end clash-card wizard-->*/}
			</div>

		</div>
	);
};

export default Programmes;
