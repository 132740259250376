import React from 'react';
import './about.scss';

const About = () => {
    return (
        <div className="container about-page">
            <div className="left">
                <h2>Qui sommes-nous</h2>
                <p>
                    Bienvenue à la 23e Communauté Évangélique du Congo – Paroisse de France. Notre paroisse s'est
                    engagée
                    à être un phare de foi, d'espoir et d'amour dans notre
                    communauté. Enracinée dans les enseignements de l'Évangile, nous célébrons la diversité et l'unité
                    parmi les enfants de Dieu.
                </p>
                <h2>Notre Mission</h2>
                <h3>Notre mission est de vivre l'amour du Christ. Nous le faisons en:</h3>
                <p>
                    <ul>
                        <li>Cultivant une communauté de foi vivante et accueillante.</li>
                        <li>Encourageant chaque personne à grandir spirituellement.</li>
                        <li>Agissant comme agents de changement positif dans notre société.</li>
                    </ul>
                </p>
                <h2>Notre Vision</h2>
                <p>
                    Nous rêvons d'une communauté où chaque personne est valorisée, soutenue et inspirée à atteindre son
                    plein potentiel en Christ. Avec une variété de programmes et d'initiatives, nous œuvrons pour que ce
                    rêve devienne une réalité.
                </p>
                <h2>Rencontrez nos Leaders</h2>
                <p>
                    Nos pasteurs et leaders sont dévoués à servir et guider notre paroisse vers une foi plus profonde et
                    une plus grande communion. Ils sont les gardiens de nos valeurs et les guides de notre voyage
                    spirituel.
                </p>
                <h2>Rejoignez-Nous</h2>
                <p>
                    Que vous soyez à la recherche d'une maison spirituelle ou d'une communauté qui fait la différence,
                    vous êtes le bienvenu ici. Venez comme vous êtes, pour partager, apprendre, servir, et célébrer la
                    vie en Christ.
                </p>
                <p>
                    Pour en savoir plus sur nos services, groupes de prière, et actions communautaires, contactez-nous
                    ou visitez-nous. Nous sommes impatients de faire votre connaissance.
                </p>

            </div>
            <div className="right">
            </div>

        </div>
    );
};

export default About;
