import './styles/_Predication.scss'
import {useSelector} from "react-redux";


const Predications = () => {
	const { videos } = useSelector(store =>store.youtubeVideos)
	const firstTen = videos.slice(0, 12);

	return (
		<div className="container predication">
			{firstTen.map(item=>{
				const video = item.id.videoId;
				const title = (item.snippet.title).split(":")[1].trim();
				return (
					<div className="pred-card">
						<iframe width="250" height="200"
								src={`https://www.youtube.com/embed/${video}`}
								title="YouTube video player" frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
						>
						</iframe>
						<h3>{title}</h3>
					</div>
				)})
			}
		</div>
	);
};

export default Predications;
