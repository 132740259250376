import React from 'react';
import {Form} from "react-router-dom"
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formSubmit} from "../features/contactForm/contactFormSlice";

const ContactUs = () => {
    const dispatch = useDispatch();
    const handleFormSubmit = async (e)=>{
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const name = formData.get("name");
        const email = formData.get("email");
        const message = formData.get("message")
        const object = "Formulaire de contact";

        if (!email || !message || !name){
            toast.error("Veuillez remplir le champ vide");
            return;
        }

        await dispatch(formSubmit({ name, email, message, object}))
            .unwrap()
            .catch((err) => {
                console.error(err)
            });
        form.reset();
    }

    return (<div className="container">
        <p>Votre voix compte pour nous. Le formulaire de contact ci-dessous est votre lien direct avec notre équipe
            pastorale et administrative. En le remplissant, vous pouvez entrer en contact avec nous.
        </p>
        <p>Nous nous efforçons de répondre à toutes les requêtes dans le plus bref délai, et chaque
            message est traité avec le plus grand soin et discrétion.
        </p>

        <div className="col-md-4">
            <div className="widget">
                <h3 className="widget-title">Contactez-nous</h3>
                <Form onSubmit={handleFormSubmit} className="contact-form">
                    <div className="row">
                        <div className="col-md-6">
                            <input
                                name="name"
                                type="text"
                                placeholder="Votre nom..."
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                name="email"
                                type="email"
                                placeholder="Votre email..."
                            />
                        </div>
                    </div>

                    <textarea
                        name="message"
                        placeholder="Votre message..."
                    ></textarea>
                    <div className="text-right">
                        <input type="submit" value="Envoyer"/>
                    </div>
                </Form>
            </div>
        </div>
    </div>);
};

export default ContactUs;
