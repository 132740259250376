import { createSlice} from "@reduxjs/toolkit";
import {videos} from "../../api/Data";


const initialState = {
    isLoading: false,
    videos:videos,
}

// export const getYoutubeVideos = createAsyncThunk(
//     'youtube/videos',
//     async (formData, { rejectWithValue }) => {
//         try {
//             const url = process.env.REACT_APP_BACKEND_URI + "/_youtube";
//             const response = await axios.get(url);
//             return response.data;
//         } catch (err) {
//             if (err.response) {
//                 return rejectWithValue(err.response.data);
//             }
//             return rejectWithValue(err.message);
//         }
//     }
// )

const youtubeVideosSlice = createSlice({
    name: "youtube",
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        // builder
            // .addCase(getYoutubeVideos.pending, (state)=>{
            //     state.isLoading = true;
            // })
            // .addCase(getYoutubeVideos.fulfilled, (state, {payload})=>{
            //     // console.log(payload);
            //     state.videos = payload;
            //     state.isLoading = false;
            // })
            // .addCase(getYoutubeVideos.rejected, (state, {payload})=>{
            //     state.isLoading = true;
            //     console.error(payload);
            // })
    }
})

export default youtubeVideosSlice.reducer;
