import React from 'react';
import Address from "../Address";
import ContactUs from "../ContactUs";
import Copyright from "./Copyright";
import SideNav from "./SideNav";
import './footer.scss';

const Footer = () => {
	return (
		<footer className="site-footer">
			<div className="container">
				<div className="row">
					<Address />
					<ContactUs/>
					<SideNav/>
				</div>
				<Copyright/>
			</div>
		</footer>
	);
};

export default Footer;
