import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isLoading: false,
    singleNews : [],
    allNews : [],
}

export const getAllNews = createAsyncThunk(
    "getAllNews",
    async (_,  { rejectWithValue })=>{
        try {
            const url = process.env.REACT_APP_BACKEND_URI + '/news';
            const response = await axios.get(url);
            return response.data;
        } catch (err) {
            if (err.response) {
                return rejectWithValue(err.response.data);
            }
            return rejectWithValue(err.message);
        }
    }
)

const publicationsSlice = createSlice({
    name: "publications",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
            .addCase(getAllNews.pending, (state)=>{
                state.isLoading = true;
            })
            .addCase(getAllNews.fulfilled, (state, {payload})=>{
                state.allNews =payload;
                state.isLoading = false;
            })
            .addCase(getAllNews.rejected, (state, {payload})=>{
                state.isLoading = false;
            })
    }
})

export default publicationsSlice.reducer;
