import React from 'react';
import {Box, Button} from "@mui/material";
import {useResponsive} from "../hooks/use-responsive";

const MemberBtn = ({isMobile}) => {
    const isDesktop = useResponsive("up", "sm");

    return (
        <>
            {isDesktop &&
                !isMobile &&
                <Box sx={{position: "absolute", top: isDesktop ? "2rem" : 10, right: isDesktop ? "5rem" : 10}}>
                    <Button
                        variant={"outlined"}
                        component={"a"}
                        href={"https://admin.cecfrance.com"}
                    >
                        Espace Membre
                    </Button>
                </Box>
            }
            {isMobile &&
                !isDesktop &&
                <Box sx={{position: "", top: "", right: ""}}>
                    <Button
                        variant={"outlined"}
                        component={"a"}
                        href={"https://admin.cecfrance.com"}
                    >
                        Espace Membre
                    </Button>
                </Box>
            }
        </>
    );
};

export default MemberBtn;
