import {configureStore} from "@reduxjs/toolkit";
import contactFormSlice from "./features/contactForm/contactFormSlice";
import youtubeVideosSlice from "./features/youtubeVideos/youtubeVideosSlice";
import publicationsSlice from "./features/publications/publicationsSlice";

export const store = configureStore({
    reducer:{
        contactForm: contactFormSlice,
        youtubeVideos: youtubeVideosSlice,
        publications: publicationsSlice
    }
})
