import React from 'react';
import {Stack} from "@mui/material";
import {useResponsive} from "../hooks/use-responsive";
import afficheImg from "../assets/paques.jpg"

const Seminaire = () => {
    const isDesktop = useResponsive("up", "sm");
    const isMobile = useResponsive("down", "sm");
    console.log(isMobile, "isMobile");

    console.log(isDesktop, "isDesktop");
    return (
        <>
            {isDesktop ?
                <Stack mt={2} mb={5} textAlign={"center"}
                       sx={{
                           width: "100%",
                           height: "content",
                           boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
                           borderRadius: 2
                       }}>
                    <img src={afficheImg} alt={"seminaire"} width={"100%"} height={"100%"}
                         style={{objectFit:"100%"}}/>
                </Stack>
                :
                <Stack mt={2} mb={5} textAlign={"center"}
                       sx={{
                           width: "100%",
                           height: "max-content",
                           boxShadow: "4px 4px 8px 4px rgba(34, 60, 80, 0.2)",
                           borderRadius: 2
                       }}>
                    <img src={afficheImg} alt={"seminaire"} width={"100%"} height={"100%"}
                         style={{objectFit: "cover", borderRadius: 2}}/>
                </Stack>

            }

        </>
    )
        ;
};

export default Seminaire;
