import React, {useEffect, useState} from 'react';

const Copyright = () => {
	const creationYear = 2004;
	const [currentYear, setCurrentYear]= useState('')
	useEffect(()=>{
		let date = new Date().getFullYear()
		setCurrentYear(date)
	}, [])
	// console.log(creationYear)
	// console.log(currentYear)
	// const valYear =
	return (
		<p className="colophon text-center">
			Copyright <span id="copyright">{` ${creationYear} - ${currentYear}`}</span> Communauté evangelique
			Internationale. Tous droit-reservés
			
		</p>
	);
};

export default Copyright;