import React from 'react';
import pageError from './../../assets/pageError.svg'
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="container">
            <center>
                <img src={pageError} alt="not found"/>
                <h3>Ohh! Page Not Found</h3>
                <p>We can't seem to find the page you're looking</p>
                <Link to='/'>Retour à la page principale</Link>
            </center>

        </div>
    );
};

export default NotFound;
