import React from 'react';
import {Link} from "react-router-dom";


const Blog = () => {
	return (
		<div>
			<div>
				<Link to="/login">Se connecter</Link> <br/>
				<Link to="/create-post">Créer une publication</Link>
			</div>
		</div>
	);
};

export default Blog;