import React from 'react';
import MenuBar from "./MenuBar";
import "./_NavBar.scss";

const MobileMenu = () => {

    return (
        <div
            className={"expanded container"}
        >
            <MenuBar />
        </div>
    );
};

export default MobileMenu;
