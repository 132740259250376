import React from 'react';
import './hero.scss';

const Hero = () => {
    return (
        <div className="container hero-page">
            {
                data.map(item => (
                    <div className={`${item.className} hero-item`} key={item.id}>
                        <div className="art">
                            <img src={item.imageUrl} alt=""/>
                        </div>
                        <div className="content">
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                    </div>
                ))
            }

        </div>);
};


const data = [
    {
        id: 1,
        title: "Un Lieu de Foi, d'Espérance et d'Amour",
        description: "Bienvenue dans notre famille spirituelle où chaque cœur trouve sa place et où chaque âme s'épanouit. Rejoignez-nous pour célébrer la grâce divine et forger des liens de fraternité qui transcendent les frontières.",
        className: 'one',
        imageUrl: "./carousel/1.jpg",
    },
    {
        id: 2,
        title: "Vivez la Parole de Dieu",
        description: "Découvrez avec nous un chemin de vie illuminé par l'amour du Christ. À travers nos cultes, nos études bibliques et nos actions solidaires, nous cultivons un espace où la foi se vit activement et où l'espérance se partage.",
        className: 'two',
        imageUrl: "./carousel/church_2.jpg",
    },
    {
        id: 3,
        title: "Une Communauté Unie dans la Diversité",
        description: "Notre force réside dans notre diversité. Nous sommes un tissu de cultures, d'expériences et d'histoires, unis dans un même désir de grandir en Christ et de manifester son amour dans le monde.",
        className: 'three',
        imageUrl: "./carousel/3.jpg",
    },
    {
        id: 4,
        title: "Engagez-vous et Transformez",
        description: "Explorez les multiples manières de vous engager dans notre communauté. Que ce soit à travers le service, l'éducation chrétienne, ou les groupes de vie, trouvez votre voie pour contribuer au bien commun et à l'épanouissement personnel.",
        className: 'four',
        imageUrl: "./carousel/2.jpg",
    },

]

export default Hero;
