import ContactForm from "../components/ContactForm/ContactForm";

const Contact = () => {
	return (
		<div className="site-content">
			<ContactForm/>
		</div>
	);
};

export default Contact;
