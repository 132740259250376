import "./style_contactForm.css";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formSubmit} from "../../features/contactForm/contactFormSlice";
import {useForm} from "react-hook-form";

const ContactForm = () => {
    const dispatch = useDispatch();
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const onSubmit = async (data) => {
        // e.preventDefault();
        const { name, email, message, subject } = data;
        const object = subject;

        if (!email || !message || !name || !object) {
            toast.error("Veuillez remplir le champ vide");
            return;
        }
        try {
            await dispatch(formSubmit({ name, email, message, object })).unwrap();
            reset(); // Réinitialiser le formulaire après l'envoi réussi
        } catch (err) {
            toast.error(err.message || "Une erreur est survenue lors de l'envoi du formulaire.");
        }
    }

    return (
        <div className="container contact-page">
            <div className="left">
                <h2>Contactez-Nous</h2>
                <h3>Votre présence ici est une bénédiction pour nous. <br/>
                    Utilisez le formulaire ci-dessous pour :</h3>
                <p>
                    <ul>
                        <li>Demander des prières personnalisées pour vous ou vos proches.</li>
                        <li>Obtenir des informations sur nos services, groupes d'étude biblique, et événements à
                            venir.
                        </li>
                        <li>Recevoir de l'aide et des conseils spirituels.</li>
                    </ul>
                </p>

                <h3>Rapide et Simple</h3>
                <p>Remplissez votre nom, email et partagez votre message. Nous vous répondrons promptement.</p>
            </div>


            <div className="row right">
                <div className="col-md-12">
                    <div className="contact_form">
                        <div className="col-12 text-center">
                            {/* <div className='contactForm'> */}
                            <form
                                id="contactform"
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate
                                className="row"
                            >
                                {/* Row 1 of form */}
                                <div className="row-fluid formRow" id="nomEmail">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-floating">
                                        <input
                                            type="text"
                                            name="name"
                                            {...register("name", {
                                                required: {
                                                    value: true,
                                                    message: "Veuillez saisir votre nom et Prénom",
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message:
                                                        "Désolé vous ne pouvez pas dépasser 50 caractères",
                                                }
                                            })}
                                            className="form-control"
                                            placeholder="Nom & Prénom"
                                        />
                                        <br/>
                                        {errors.name && (
                                            <span className="errorMessage">{errors.name.message}</span>
                                        )}
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <input
                                            type="email"
                                            name="email"
                                            // id='eml'
                                            {...register("email", {
                                                required: true,
                                                pattern:
                                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            })}
                                            className="form-control"
                                            placeholder="Adresse Email"
                                        />
                                        <br/>
                                        {errors.email && (
                                            <span className="errorMessage">
                                              Veuillez saisir une adresse email valide
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <input
                                        type="text"
                                        name="subject"
                                        {...register("subject", {
                                            required: {
                                                value: true,
                                                message: "Veuillez saisir l'objet de votre message ",
                                            },
                                            maxLength: {
                                                value: 75,
                                                message:
                                                    "Désolé vous ne pouvez pas dépasser 75 caractères",
                                            },
                                        })}
                                        className="form-control"
                                        placeholder="Objet du Message"
                                    />
                                    <br/>
                                    {errors.subject && (
                                        <span className="errorMessage">{errors.subject.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <textarea
                                        rows={3}
                                        name="message"
                                        // id='msg'
                                        {...register("message", {
                                            required: true,
                                        })}
                                        className="form-control"
                                        placeholder="Message"
                                    ></textarea>
                                    <br/>
                                    {errors.message && (
                                        <span className="errorMessage">
                                        Veuillez saisir votre message
                                      </span>
                                    )}
                                </div>
                                {/* </div> */}
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center"
                                    id="btn-envoi1"
                                >
                                    <button
                                        className="btn btn-light btn-radius btn-brd grd1 btn-block"
                                        type="submit"
                                    >
                                        Envoyer
                                    </button>
                                </div>
                            </form>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
