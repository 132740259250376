import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './sass/style.scss'
import {Provider} from "react-redux";
import 'react-toastify/dist/ReactToastify.css'
import {store} from "./store";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
        <ToastContainer position="top-center" />
    </Provider>

);
