import React, { useEffect} from 'react';
import './StylePublication.scss'
import {useDispatch, useSelector} from "react-redux";
import {getAllNews} from "../features/publications/publicationsSlice";

const NewPubPage = () => {
    const dispatch = useDispatch();
    const { allNews } = useSelector(store => store.publications)
    const posts = allNews;


    useEffect(() => {
        dispatch(getAllNews())
    }, [dispatch])

    function postRender(post) {
        if (post) {
            return (
                <div className="w3-col l8 s12">
                    {posts.map((item) => {
                        return (
                            <div className="w3-container w3-white w3-margin w3-padding-large" key={item.id}>
                                <div className="w3-center">
                                    <h3><b>{item.title}</b></h3>
                                    <p className="w3-opacity">
                                        Publié le
                                        <span className="w3-opacity"> {item.ladate}</span>
                                    </p>
                                </div>

                                <div className="w3-justify">
                                    <p>{item.content}</p>
                                    <p className="w3-right">
                                                <span
                                                    className="w3-opacity"
                                                    onClick="likeFunction(this)"
                                                >
                                                    le {item.user}
                                                </span>
                                    </p>

                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

        return (
            <div className="w3-light-grey">
                <div className="w3-content" style={{maxWidth: "1400px"}}>
                    <header className="w3-container w3-center w3-padding-10 w3-white">
                        <h1 className="w3-xxxlarge">
                            <b>Nos Annonces</b>
                        </h1>
                        <h6>ici vous pouvez lire toutes les annonces et communiquer de la paroisse</h6>
                    </header>
                    {/*<h1>Nos Publications</h1>*/}
                    <div className="w3-row w3-padding w3-border">
                        <div className="w3-col l8 s12">
                            {posts ?
                                postRender(posts) : <center> <h2>chargement en cours...</h2></center>
                            }

                        </div>
                        <div className="w3-col l4">
                            <div className="w3-white w3-margin">
                                <div className="w3-container w3-white">
                                    <h4><b> A propos de nous</b></h4>
                                    <p>
                                        Nous sommes la 23e Communauté Evangelique du Congo. Paroisse de la France Vous
                                        êtes
                                        en France ou vous avez besoin de nous contacter veuillez remplir le formulaire
                                        suivant ou nous appelez au numéro ci-dessous.
                                        Nous sommes bénis de vous voir parmi nous!
                                    </p>
                                </div>
                            </div>
                            {/*<hr/>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    export default NewPubPage;
