import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import DefaultLayout from "./components/Layout/DefaultLayout";
import {NotFound} from "./pages/NotFound";
import Home from "./pages/Home";
import MobileMenu from "./components/NavBar/MobileMenu";
import Blog from "./pages/Blog";
import Programmes from "./pages/Programmes";
import Predications from "./pages/Predications";
import About from "./pages/About/About";
import Contact from "./pages/Contact";
import NewPubPage from "./pages/NewPubPage";

export const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="/mobile-menu" element={<MobileMenu />}/>
        <Route path={"/blog"} element={<Blog/>} />
        <Route path={"/programmes"} element={<Programmes/>} />
        <Route path={"/predications"} element={<Predications/>} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/contact"} element={<Contact />} />
        <Route path={"/annonces"} element={<NewPubPage />} />
        <Route path="*" element={<NotFound />} />
    </Route>
))
