import React, {useEffect, useState} from 'react';
import Hero from "../components/Hero/Hero";
import Seminaire from "../components/Seminaire";


let dateActuel = new Date();
let dateLimit = new Date("2024-01-01");
const Home = () => {
	const [showAffiche, setShowAffiche] = useState(false);

	const handleShowAffiche = (dateAct, dateL)=>{
		if (dateAct < dateL) setShowAffiche(true)
		else setShowAffiche(false)
	}

	useEffect(() => {
		handleShowAffiche(dateActuel, dateLimit)
	}, []);
	return (
		<div className="container">
			{/*<CarouselApp/>*/}
			{showAffiche && <Seminaire />}

			<Hero />
		</div>
	);
};

export default Home;
