import React from 'react';
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import {Outlet} from "react-router-dom";

const DefaultLayout = () => {
    return (
        <>
            <NavBar />
            <main>
                <Outlet />
            </main>

            <Footer/>

        </>
    );
};

export default DefaultLayout;
