export const videos = [
    {
        "kind": "youtube#searchResult",
        "etag": "VlZs4pPkIKeveb4bhPjzDLwHDyg",
        "id": {
            "kind": "youtube#video",
            "videoId": "ZmvScs9mJRY"
        },
        "snippet": {
            "publishedAt": "2023-11-05T13:10:28Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CECE FRANCE : CULTE DU 05/11/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/ZmvScs9mJRY/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/ZmvScs9mJRY/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/ZmvScs9mJRY/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-11-05T13:10:28Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "NlSXFa3ciVbVlg4K5od3aqS5E9E",
        "id": {
            "kind": "youtube#video",
            "videoId": "ZHzGGp75_W4"
        },
        "snippet": {
            "publishedAt": "2023-11-05T13:12:31Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE : CULTE DU 05/11/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/ZHzGGp75_W4/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/ZHzGGp75_W4/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/ZHzGGp75_W4/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-11-05T13:12:31Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "NmOTGgxvn_DUfGBq3pbqnn32538",
        "id": {
            "kind": "youtube#video",
            "videoId": "Khi-csDrSJY"
        },
        "snippet": {
            "publishedAt": "2023-10-29T15:57:59Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 29/08/23",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Khi-csDrSJY/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Khi-csDrSJY/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Khi-csDrSJY/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-10-29T15:57:59Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "UDklaGXfTQON3VhosusSBLAsIJs",
        "id": {
            "kind": "youtube#video",
            "videoId": "bMT2eepGRWE"
        },
        "snippet": {
            "publishedAt": "2023-10-29T07:17:09Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/bMT2eepGRWE/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/bMT2eepGRWE/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/bMT2eepGRWE/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-10-29T07:17:09Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "a12S2NJ2RePdFVTnMZDx4RQPwY0",
        "id": {
            "kind": "youtube#video",
            "videoId": "kXj5VBuxyj0"
        },
        "snippet": {
            "publishedAt": "2023-10-23T02:03:10Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE: CULTE DU 22/10/2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/kXj5VBuxyj0/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/kXj5VBuxyj0/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/kXj5VBuxyj0/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-10-23T02:03:10Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "5rfRwCV3FZq63qiqsgI8oSqOFM4",
        "id": {
            "kind": "youtube#video",
            "videoId": "b0RRNxWeZjo"
        },
        "snippet": {
            "publishedAt": "2023-10-16T03:47:46Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE: CULTE DU 15 OCTOBRE 2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/b0RRNxWeZjo/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/b0RRNxWeZjo/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/b0RRNxWeZjo/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-10-16T03:47:46Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "dtiMtHW0mzyIqISUb4BzjJal8Uk",
        "id": {
            "kind": "youtube#video",
            "videoId": "Ij2XQxq3ewA"
        },
        "snippet": {
            "publishedAt": "2023-10-09T03:39:52Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE: culte 08 octobre 2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Ij2XQxq3ewA/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Ij2XQxq3ewA/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Ij2XQxq3ewA/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-10-09T03:39:52Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "_tvnazvV-1mUItwp7By_6XX1GSU",
        "id": {
            "kind": "youtube#video",
            "videoId": "QTyxuiVO65I"
        },
        "snippet": {
            "publishedAt": "2023-09-04T03:50:37Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE :  CULTE DU 03/09/23  PRENDS SOIN DE TA FAMILLE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/QTyxuiVO65I/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/QTyxuiVO65I/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/QTyxuiVO65I/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-09-04T03:50:37Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "fW3KXF8WBaCjrN0VT0POtJ66YTA",
        "id": {
            "kind": "youtube#video",
            "videoId": "36DePZGQpxQ"
        },
        "snippet": {
            "publishedAt": "2023-08-28T02:48:26Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 27/08/23 LA FOI AUTHENTIQUE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/36DePZGQpxQ/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/36DePZGQpxQ/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/36DePZGQpxQ/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-08-28T02:48:26Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "sAd73bTWxzZkIhYpWvey7IY9myU",
        "id": {
            "kind": "youtube#video",
            "videoId": "CDSJUvTd1jU"
        },
        "snippet": {
            "publishedAt": "2023-08-21T03:00:40Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE :  CULTE DU 20/08/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/CDSJUvTd1jU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/CDSJUvTd1jU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/CDSJUvTd1jU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-08-21T03:00:40Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "lWJ2lgcgLaHl3FzKokcw2Q_hRLw",
        "id": {
            "kind": "youtube#video",
            "videoId": "OG2FI9rxlx0"
        },
        "snippet": {
            "publishedAt": "2023-08-14T02:56:21Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : culte du dimanche 13.08.2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/OG2FI9rxlx0/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/OG2FI9rxlx0/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/OG2FI9rxlx0/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-08-14T02:56:21Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "fqgdGdSCvTJuqSl1VEq0n3QCVPQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "Yc954ZjGSPY"
        },
        "snippet": {
            "publishedAt": "2023-08-07T02:08:33Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 06/08/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Yc954ZjGSPY/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Yc954ZjGSPY/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Yc954ZjGSPY/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-08-07T02:08:33Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "GGoAkvG94hCTODAyzkqy9WqmK3g",
        "id": {
            "kind": "youtube#video",
            "videoId": "f-oTBCV5eGw"
        },
        "snippet": {
            "publishedAt": "2023-07-31T02:31:22Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE: CULTE DU 30/07/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/f-oTBCV5eGw/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/f-oTBCV5eGw/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/f-oTBCV5eGw/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-31T02:31:22Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "K4UDFx_vPmXqTtpJ4y4pFPUcDb8",
        "id": {
            "kind": "youtube#video",
            "videoId": "Wkh1uwi0vXo"
        },
        "snippet": {
            "publishedAt": "2023-07-24T02:37:54Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE: CULTE DU 23/07/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Wkh1uwi0vXo/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Wkh1uwi0vXo/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Wkh1uwi0vXo/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-24T02:37:54Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "YpbFkXhhyKUWLfrvFkZltO0ywBU",
        "id": {
            "kind": "youtube#video",
            "videoId": "wZOCo6suKTw"
        },
        "snippet": {
            "publishedAt": "2023-07-17T02:35:12Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 16/07/2023 DIEU TE CONNAIT TRÈS BIEN   PS 139 : 1 - 24",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/wZOCo6suKTw/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/wZOCo6suKTw/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/wZOCo6suKTw/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-17T02:35:12Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "FUv8Db_6_8iFSb01rzveEaX4dZ0",
        "id": {
            "kind": "youtube#video",
            "videoId": "N8scQhMMeF4"
        },
        "snippet": {
            "publishedAt": "2023-07-10T02:42:50Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 09/07/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/N8scQhMMeF4/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/N8scQhMMeF4/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/N8scQhMMeF4/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-10T02:42:50Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "6NFwSuUk5jHdc02G2oU7kAFIphk",
        "id": {
            "kind": "youtube#video",
            "videoId": "JHY9-xraXhE"
        },
        "snippet": {
            "publishedAt": "2023-07-09T05:10:51Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : LE 08/07/2023 18 ANS DE LA CHORALE CHŒUR DE GLOIRE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/JHY9-xraXhE/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/JHY9-xraXhE/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/JHY9-xraXhE/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-09T05:10:51Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "DRHig--w1h-qekpHkmDsvsI1MiM",
        "id": {
            "kind": "youtube#video",
            "videoId": "T9SVI7TX0xg"
        },
        "snippet": {
            "publishedAt": "2023-07-03T02:30:15Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 2/07/2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/T9SVI7TX0xg/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/T9SVI7TX0xg/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/T9SVI7TX0xg/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-07-03T02:30:15Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "IhkTupF7VH0Fwz1xQGIqOyW6jgo",
        "id": {
            "kind": "youtube#video",
            "videoId": "IbwcwZaSLCo"
        },
        "snippet": {
            "publishedAt": "2023-06-26T05:45:22Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU25/06/23 AVEC  BAPTÊME",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/IbwcwZaSLCo/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/IbwcwZaSLCo/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/IbwcwZaSLCo/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-06-26T05:45:22Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "abFp6WpYUOJA0agV_zCZLecXIkc",
        "id": {
            "kind": "youtube#video",
            "videoId": "oLZ7iPGnsxk"
        },
        "snippet": {
            "publishedAt": "2023-06-19T02:38:13Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 18/05/2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/oLZ7iPGnsxk/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/oLZ7iPGnsxk/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/oLZ7iPGnsxk/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-06-19T02:38:13Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "h1mbJtdDjbcKD0b2o7MsztLZLOQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "3Nn0JZ0xYZk"
        },
        "snippet": {
            "publishedAt": "2023-06-18T08:11:03Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/3Nn0JZ0xYZk/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/3Nn0JZ0xYZk/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/3Nn0JZ0xYZk/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-06-18T08:11:03Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "higuTNdyTRrT6T9bOB8lkfyGxUk",
        "id": {
            "kind": "youtube#video",
            "videoId": "1v7QWNDHSCU"
        },
        "snippet": {
            "publishedAt": "2023-06-12T03:16:15Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 11/06/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/1v7QWNDHSCU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/1v7QWNDHSCU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/1v7QWNDHSCU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-06-12T03:16:15Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "-MVtorLF_1Dl4dFn3tC_Z4dDUI8",
        "id": {
            "kind": "youtube#video",
            "videoId": "rEwv4QP9eoM"
        },
        "snippet": {
            "publishedAt": "2023-06-05T02:40:18Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE : CULTE DU 04/06/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/rEwv4QP9eoM/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/rEwv4QP9eoM/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/rEwv4QP9eoM/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-06-05T02:40:18Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "TfFyYMYAjk6wZNaO8mgIv1MFlNQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "78RKZLbUJxU"
        },
        "snippet": {
            "publishedAt": "2023-05-29T02:38:20Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE :  LA PENTECÔTE : CROITRE EN COMMUNAUTÉ PAR LE SAINT-ESPRRIT  / PASTEUR NSUMBU",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/78RKZLbUJxU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/78RKZLbUJxU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/78RKZLbUJxU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-29T02:38:20Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "8x-EYm1aDk01TO1KsJ7QT4q65zU",
        "id": {
            "kind": "youtube#video",
            "videoId": "Y3gkK50gKyo"
        },
        "snippet": {
            "publishedAt": "2023-05-22T01:55:45Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE :  CULTE DU 21/05/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Y3gkK50gKyo/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Y3gkK50gKyo/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Y3gkK50gKyo/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-22T01:55:45Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "cL8gQHQQvS13vyIKHlz8J8kAVqw",
        "id": {
            "kind": "youtube#video",
            "videoId": "XMgBvJbGowY"
        },
        "snippet": {
            "publishedAt": "2023-05-15T03:54:55Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE: CULTE DE DIMANCHE 14 MAI 2023 CONSÉCRATION DE DIACRES PAR REV. AXEL MALUNGIDI",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/XMgBvJbGowY/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/XMgBvJbGowY/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/XMgBvJbGowY/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-15T03:54:55Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "IECRjQ0oLGvqCyCPkCXNYnr71Ik",
        "id": {
            "kind": "youtube#video",
            "videoId": "M4G5j0-6JTU"
        },
        "snippet": {
            "publishedAt": "2023-05-07T16:46:41Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : BÉNÉDICTION NUPTIALE  SR HORTENCE ET FR ERIC-NELSON",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/M4G5j0-6JTU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/M4G5j0-6JTU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/M4G5j0-6JTU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-07T16:46:41Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "WkKOaqHPM9yiBwjKg2MSkYx1tiE",
        "id": {
            "kind": "youtube#video",
            "videoId": "EpKocKXcs34"
        },
        "snippet": {
            "publishedAt": "2023-05-07T10:30:13Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/EpKocKXcs34/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/EpKocKXcs34/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/EpKocKXcs34/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-07T10:30:13Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "uu76KrEw5u_E4vb3OCII-5t7q58",
        "id": {
            "kind": "youtube#video",
            "videoId": "QrK7NET6jhs"
        },
        "snippet": {
            "publishedAt": "2023-05-01T02:30:33Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 30/04/23 POUR ÊTRE DISCIPLE DE JÉSUS-CHRIST  /  FR KULUTA TEMBE ÉTIENNE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/QrK7NET6jhs/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/QrK7NET6jhs/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/QrK7NET6jhs/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-05-01T02:30:33Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "80KYcd3Zuc_bsO1iCWMmU5-lVpY",
        "id": {
            "kind": "youtube#video",
            "videoId": "DIkrARGLv0c"
        },
        "snippet": {
            "publishedAt": "2023-04-30T23:46:02Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/DIkrARGLv0c/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/DIkrARGLv0c/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/DIkrARGLv0c/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-30T23:46:02Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "jcpQf_o-AA73jrjUEHrQUYkecfw",
        "id": {
            "kind": "youtube#video",
            "videoId": "g_h1HPu-BPg"
        },
        "snippet": {
            "publishedAt": "2023-04-24T02:22:35Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 23/04/23  SOYEZ LES ENFANTS DE LA LUMIÈRE PAR LE FR JEAN BAUDOUIN MAMPUYA",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/g_h1HPu-BPg/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/g_h1HPu-BPg/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/g_h1HPu-BPg/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-24T02:22:35Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "Oxs4kQuhCi4E6Ay0RNrkXBLWlzg",
        "id": {
            "kind": "youtube#video",
            "videoId": "lN-B4pGcQgg"
        },
        "snippet": {
            "publishedAt": "2023-04-17T02:38:18Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE :  CULTE DU 16/04/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/lN-B4pGcQgg/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/lN-B4pGcQgg/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/lN-B4pGcQgg/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-17T02:38:18Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "FT8Bsa4q6yiCak5OWV7nBBmp4Bc",
        "id": {
            "kind": "youtube#video",
            "videoId": "hgtsc6MGJuU"
        },
        "snippet": {
            "publishedAt": "2023-04-10T02:25:25Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 09/04/23  LA RESURECTION DE JÉSUS / PASTEUR THOMAS KAYA",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/hgtsc6MGJuU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/hgtsc6MGJuU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/hgtsc6MGJuU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-10T02:25:25Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "KRw_wKfLNM-8HhIU463ZldbtOa8",
        "id": {
            "kind": "youtube#video",
            "videoId": "6wHtqJbREGY"
        },
        "snippet": {
            "publishedAt": "2023-04-09T10:25:18Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "Communauté Évangélique Internationale - Salle de réunion personnelle",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/6wHtqJbREGY/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/6wHtqJbREGY/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/6wHtqJbREGY/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-09T10:25:18Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "g2Ddu7pwfoks5NQjWFrniWtEMZQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "rRaGHJqubzU"
        },
        "snippet": {
            "publishedAt": "2023-04-09T07:25:48Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/rRaGHJqubzU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/rRaGHJqubzU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/rRaGHJqubzU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-09T07:25:48Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "RlQ9CCM_APE4M953Kb2RJggFnnA",
        "id": {
            "kind": "youtube#video",
            "videoId": "zbLGX0NeAbE"
        },
        "snippet": {
            "publishedAt": "2023-04-08T08:25:58Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "Communauté Évangélique Internationale - Salle de réunion personnelle",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/zbLGX0NeAbE/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/zbLGX0NeAbE/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/zbLGX0NeAbE/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-08T08:25:58Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "Yeec2-TfVtOAlQ7dorixCxiVa00",
        "id": {
            "kind": "youtube#video",
            "videoId": "mJjaxkK3udA"
        },
        "snippet": {
            "publishedAt": "2023-04-07T08:51:58Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : INSTITUTION DE LA CENE ET LA PRIERE A GETHSEMANE / PASTEUR ALBERT NZELELE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/mJjaxkK3udA/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/mJjaxkK3udA/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/mJjaxkK3udA/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-07T08:51:58Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "mD_Qy8YkxEBG8l8E8_qv1GBMu-I",
        "id": {
            "kind": "youtube#video",
            "videoId": "nf5y6d4uOXk"
        },
        "snippet": {
            "publishedAt": "2023-04-06T08:35:54Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : COMPLOT ET LES GRIEFS PORTES CONTRE JÉSUS / PASTEUR NZELELE ALBERT",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/nf5y6d4uOXk/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/nf5y6d4uOXk/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/nf5y6d4uOXk/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-06T08:35:54Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "TifYvLa_ApC1Ilzel93l954zsfE",
        "id": {
            "kind": "youtube#video",
            "videoId": "FA0WoRD1is0"
        },
        "snippet": {
            "publishedAt": "2023-04-05T08:34:56Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : LA PURIFICATION DU TEMPLE PAR JESUS / PASTEUR SAMBA",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/FA0WoRD1is0/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/FA0WoRD1is0/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/FA0WoRD1is0/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-05T08:34:56Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "Vw4JWV2dlzC8xLD8gEvZgoLOfPU",
        "id": {
            "kind": "youtube#video",
            "videoId": "Ev2XoBB032c"
        },
        "snippet": {
            "publishedAt": "2023-04-04T08:47:03Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C : SEMINAIRE PASCAL INTRODUCTION FR JB MAMPUYA",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/Ev2XoBB032c/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/Ev2XoBB032c/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/Ev2XoBB032c/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-04T08:47:03Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "Yr9M8mTu3NXX-nra1XeUcDYJnEQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "x4IzkbXtt3s"
        },
        "snippet": {
            "publishedAt": "2023-04-03T02:30:47Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 02/04/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/x4IzkbXtt3s/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/x4IzkbXtt3s/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/x4IzkbXtt3s/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-03T02:30:47Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "ZQuY06gVcO0bCXhsZxbM0wTiMO8",
        "id": {
            "kind": "youtube#video",
            "videoId": "m-29eE3BrgA"
        },
        "snippet": {
            "publishedAt": "2023-04-02T10:14:06Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/m-29eE3BrgA/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/m-29eE3BrgA/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/m-29eE3BrgA/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-02T10:14:06Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "dTsZ7QGO5ToMlUytDcDZj7piZR0",
        "id": {
            "kind": "youtube#video",
            "videoId": "TVvoqwbjewQ"
        },
        "snippet": {
            "publishedAt": "2023-04-02T04:16:54Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE: CULTE D&#39;ACTION DE GRACE DE NOTRE MAMAN NGOMBO",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/TVvoqwbjewQ/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/TVvoqwbjewQ/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/TVvoqwbjewQ/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-04-02T04:16:54Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "qHzoic4BcLWXP7y65d6-UjLAXUk",
        "id": {
            "kind": "youtube#video",
            "videoId": "z3cFqZ9t3Z8"
        },
        "snippet": {
            "publishedAt": "2023-03-27T02:23:09Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "CEC FRANCE : CULTE DU 26/03/23 LA FOI VICTORIEUSE",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/z3cFqZ9t3Z8/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/z3cFqZ9t3Z8/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/z3cFqZ9t3Z8/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-03-27T02:23:09Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "Fe1aHN9yFoXzS7tnbi4ITIb2qSQ",
        "id": {
            "kind": "youtube#video",
            "videoId": "O6Agq2TGsf4"
        },
        "snippet": {
            "publishedAt": "2023-03-20T03:46:10Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 19/03/2023",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/O6Agq2TGsf4/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/O6Agq2TGsf4/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/O6Agq2TGsf4/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-03-20T03:46:10Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "S6DtH_pf4H0MHeSUvRLieTX2If4",
        "id": {
            "kind": "youtube#video",
            "videoId": "GVnw_WPCDKU"
        },
        "snippet": {
            "publishedAt": "2023-03-20T01:00:54Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/GVnw_WPCDKU/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/GVnw_WPCDKU/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/GVnw_WPCDKU/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-03-20T01:00:54Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "ccUKltsimuj2crakc_JSYrVmLFM",
        "id": {
            "kind": "youtube#video",
            "videoId": "R_4sqmF6FKE"
        },
        "snippet": {
            "publishedAt": "2023-03-13T04:48:20Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 12/03/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/R_4sqmF6FKE/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/R_4sqmF6FKE/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/R_4sqmF6FKE/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-03-13T04:48:20Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "KRpeby01pEq4w1ijOJrbZqN3cOo",
        "id": {
            "kind": "youtube#video",
            "videoId": "_lwoDpsdlrQ"
        },
        "snippet": {
            "publishedAt": "2023-03-05T18:15:29Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 28/08/22",
            "description": "",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/_lwoDpsdlrQ/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/_lwoDpsdlrQ/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/_lwoDpsdlrQ/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-03-05T18:15:29Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "DFsZ_JDG1mrLCInpsd1gDKfmFwk",
        "id": {
            "kind": "youtube#video",
            "videoId": "AMyimLdqFpg"
        },
        "snippet": {
            "publishedAt": "2023-02-27T03:23:23Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 26/02/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/AMyimLdqFpg/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/AMyimLdqFpg/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/AMyimLdqFpg/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-02-27T03:23:23Z"
        }
    },
    {
        "kind": "youtube#searchResult",
        "etag": "ZuQjd-NcO-M7XF6lUrNH9N9HJWg",
        "id": {
            "kind": "youtube#video",
            "videoId": "IMYYCQ_Av-E"
        },
        "snippet": {
            "publishedAt": "2023-02-20T03:44:31Z",
            "channelId": "UCFUhOSnU06fb1qhTTuMkUDA",
            "title": "C.E.C FRANCE : CULTE DU 19/02/23",
            "description": "Communauté Évangélique Internationale.",
            "thumbnails": {
                "default": {
                    "url": "https://i.ytimg.com/vi/IMYYCQ_Av-E/default.jpg",
                    "width": 120,
                    "height": 90
                },
                "medium": {
                    "url": "https://i.ytimg.com/vi/IMYYCQ_Av-E/mqdefault.jpg",
                    "width": 320,
                    "height": 180
                },
                "high": {
                    "url": "https://i.ytimg.com/vi/IMYYCQ_Av-E/hqdefault.jpg",
                    "width": 480,
                    "height": 360
                }
            },
            "channelTitle": "Communauté Evangelique Internationale",
            "liveBroadcastContent": "none",
            "publishTime": "2023-02-20T03:44:31Z"
        }
    }
]
