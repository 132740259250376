import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from "react-toastify";

const initialState = {
    isSubmit: false,
    submitMessage : ''
}

export const formSubmit = createAsyncThunk(
    'form/submit',
    async (formData, { rejectWithValue }) => {
        try {
            const url = process.env.REACT_APP_BACKEND_URI + "/contact";
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "charset": 'utf-8'
                }
            });
            return response.data;
        } catch (err) {
            if (err.response) {
                return rejectWithValue(err.response.data);
            }
            return rejectWithValue(err.message);
        }
    }
)

const contactFormSlice = createSlice({
    name : "contactForm",
    initialState,
    reducers:{
    },
    extraReducers: (builder)=>{
        builder
            .addCase(formSubmit.pending, (state)=>{
                state.isSubmit = false;
            })
            .addCase(formSubmit.fulfilled, (state, {payload})=>{
                state.submitMessage =payload.message;
                state.isSubmit = true;
                toast.success(payload.message);
            })
            .addCase(formSubmit.rejected, (state, {payload})=>{
                state.submitMessage = payload.message;
                state.isSubmit = true;
                toast.error(payload.message);
            })
    }

})

export default contactFormSlice.reducer;
