import React from 'react';
import {NavLink} from "react-router-dom";

const SideNav = () => {
	return (
		<div className="col-md-4">
			<div className="widget clearfix">
				<div className="widget-title">
					<h3>Liens</h3>
				</div>
				<ul className="footer-links ">
					<NavLink className="menu-item"
							 to={'/'}
					>
						<li>ACCUEIL</li>
					</NavLink>
						
						<a rel='noreferrer' className='menu-item' href="https://blog.cecfrance.com" target="_blank"><li>Actualités & Blog</li></a>
					<NavLink to={'/programmes'} className="menu-item">
						<li>Programmes</li>
					</NavLink>
					<NavLink to={"/predications"} className="menu-item">
						<li>Prédications</li>
					</NavLink>
					<NavLink to={"/about"} className="menu-item">
						<li>Qui sommes-nous?</li>
					</NavLink>
					<NavLink to={'/contact'} className="menu-item">
						<li>Contact</li>
					</NavLink>
				</ul>
			</div>
		</div>
	);
};

export default SideNav;