import React from 'react';
import {NavLink} from "react-router-dom";

const MenuBar = () => {

    return (
        <ul className="menu">
            <NavLink className="menu-item"
                     to={'/'}
                     style={({isActive}) => ({
                         borderBottomColor: isActive ? "#8f7655" : "",
                         borderWidth: "2px"
                     })}
            >
                <li>ACCUEIL<small>Bienvenue</small></li>
            </NavLink>
            <NavLink to={'/annonces'} className="menu-item">
                <li>Actualités & Annonces <small>Annonce de la paroisse</small></li>
            </NavLink>
            <NavLink to={'/programmes'} className="menu-item">
                <li>Programmes <small>Notre Agenda</small></li>
            </NavLink>
            <NavLink to={"/predications"} className="menu-item">
                <li>Prédications <small>Reduffission des Prédications</small></li>
            </NavLink>
            <NavLink to={"/about"} className="menu-item">
                <li>Qui sommes-nous?<small>En savoir plus</small></li>
            </NavLink>
            <NavLink to={'/contact'} className="menu-item">
                <li>Contact <small>formulaire de contact</small></li>
            </NavLink>
        </ul>
    );
};

export default MenuBar;
