import React, {useEffect, useState} from 'react';
import MenuBar from "./MenuBar";
import './_NavBar.scss'
import { useLocation, useNavigate} from "react-router-dom";
import MemberBtn from "../MemberBtn";

const NavBar = () => {
    const [isSticky, setIsticky] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const target = '/mobile-menu';

    const toggleRoute = () => {
        if (location.pathname === target) {
            navigate("/");
        } else {
            navigate(target);
        }
    }

    const handleScroll = () => {
        setIsticky(window.pageYOffset > 200)
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <header className="site-header">
            <div className="container">
                <div className="branding" style={{paddingBottom: "1rem"}}>
                    <img
                        src="images/logocec.png"
                        alt=""
                        className="logo"
                        // style={style}
                    />
                    <h1 className="site-title">COMMUNAUTE EVANGELIQUE INTERNATIONALE</h1>
                    <MemberBtn isMobile/>
                </div>
                <div className={isSticky ? "main-navigation nav-fixed" : "main-navigation"}>
                    <button onClick={toggleRoute} className="menu-toggle"><i className="fa fa-bars"></i> Menu</button>
                    <MenuBar/>
                </div>

            </div>
            <MemberBtn />
        </header>
    );
};

export default NavBar;
