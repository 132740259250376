import React from 'react';

const Address = () => {
    return (
        <div className="col-md-4">
            <div className="widget">
                <h3 className="widget-title">Nos Coordonnées</h3>
                <p>
                    Bienvenue à la 23e Communauté Évangélique du Congo, Paroisse de France. Que vous soyez en France ou
                    ailleurs, nous sommes à portée de clic ou d'appel.
                </p>
                <h3>Restons Connectés</h3>
                <p>
                    Pour toute question ou demande, le formulaire de contact est votre pont direct avec nous. Si vous
                    préférez une réponse immédiate, notre ligne est ouverte :
                </p>
                <ul className="address">
                    <li>
                        <i className="fa fa-map-marker"></i> 1-3 rue des Marguerites, <br/>
                        77420 champs sur Marne 75042
                    </li>
                    <li className="phone">
                            <i className="fa fa-phone"></i>
                            <a href="tel:+33 (753) 58 53 17"> <button className="btn-block">Appeler le +33 (753) 58 53 17</button></a>
                    </li>
                    <li><i className="fa fa-envelope"></i> info@cecfrance.com</li>
                </ul>
            </div>
        </div>
    );
};

export default Address;
